import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const HakuenTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "受注番号", value: "acceptNo" },
  { text: "仕様書No", value: "specificationNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "条長(m)", value: "length" },
  { text: "数量明細(m)", value: "suryoMeisai" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class HakuenTable {
  workDate: string;
  updateTs: string | null;
  acceptNo: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  length: number | null;
  suryoMeisai: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    acceptNo: string | null;
    specificationNo: string | null;
    itemName: string | null;
    size: string | null;
    length: number | null;
    suryoMeisai: string | null;
    abnormality: string | null;
    biko: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.acceptNo = props.acceptNo;
    this.specificationNo = props.specificationNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.length = props.length;
    this.suryoMeisai = props.suryoMeisai;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const HakuenText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  postCode: { text: "部署コード", value: "postCode" },
  machineCode: { text: "機械名", value: "machineCode" },
  worker: { text: "作業者", value: "worker" },
  inspectionFlag: { text: "機械点検記録", value: "inspectionFlag" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFlag: { text: "受注番号完了", value: "successFlag" },
  specificationNo: { text: "仕様書No", value: "specificationNo" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  preProcessDrum: { text: "前工程ドラムNo", value: "preProcessDrum" },
  windingDrum: { text: "巻取りドラムNo", value: "windingDrum" },
  length: { text: "条長(m)", value: "length" },
  suryoMeisai: { text: "数量明細(m)", value: "suryoMeisai" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Hakuen extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  worker: string | null;
  inspectionFlag: string | null;
  acceptNo: string | null;
  successFlag: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  preProcessDrum: string | null;
  windingDrum: string | null;
  length: number | null;
  suryoMeisai: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Hakuen);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Hakuen | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Hakuen) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.worker = _props.worker;
      this.inspectionFlag = _props.inspectionFlag;
      this.acceptNo = _props.acceptNo;
      this.successFlag = _props.successFlag;
      this.specificationNo = _props.specificationNo;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.preProcessDrum = _props.preProcessDrum;
      this.windingDrum = _props.windingDrum;
      this.length = _props.length;
      this.suryoMeisai = _props.suryoMeisai;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "HAKUEN";
    this.machineCode = null;
    this.worker = null;
    this.inspectionFlag = "0";
    this.acceptNo = null;
    this.successFlag = "0";
    this.specificationNo = null;
    this.itemName = null;
    this.size = null;
    this.preProcessDrum = null;
    this.windingDrum = null;
    this.length = null;
    this.suryoMeisai = null;
    this.abnormality = null;
    this.biko = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
