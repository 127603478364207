
import Vue from "vue";
import moment from "moment";

//class
import { DailyWorkReportKey } from "@/models/dailyWorkReport/DailyWorkReportKey";
import {
  Hakuen,
  HakuenText,
  HakuenTable,
  HakuenTableHeaders
} from "@/models/dailyWorkReport/Hakuen";
import { CodeKbnList } from "@/models/codeMaster/Code";
import {
  PulldownResponse,
  codeKbnFilter
} from "@/models/response/PulldownResponse";
import {
  CheckboxGroupItems,
  pulldownToCheckItems
} from "@/components/input/CheckboxGroup.vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

// Components
import { Headers } from "@/components/input/DailyWorkReportInput.vue";
import DailyWorkReportFormDialog from "@/components/dialog/DailyWorkReportFormDialog.vue";
import DataIterators from "@/components/table/dailyworkreport/dataiterators/DataIterators.vue";
import Snackbar from "@/components/dialog/Snackbar.vue";
import Overlay from "@/components/dialog/Overlay.vue";
import Shain from "@/models/Shain";
import { ShainSearchRequest } from "@/models/ShainSearchRequest";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { OrderDetailsSearchResponse } from "@/models/orderDetails/response/OrderDetailsSearchResponse";
import { DATE_FORMAT } from "@/defines";
import SpecDocsDailog from "@/components/dialog/specdocs/SpecDocsDailog.vue";
import Store from "@/store/index";

const DATE = moment().format(DATE_FORMAT.DATE);

export default Vue.extend({
  name: "Hakuen",
  mixins: [Common, ShowDialogs, Api],
  components: {
    DailyWorkReportFormDialog,
    DataIterators,
    Snackbar,
    Overlay,
    SpecDocsDailog
  },
  props: {
    date: {
      type: String,
      required: false,
      default: DATE
    },
    form: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      selectDate: this.date,
      isAdd: false,
      isOpen: false,
      maxRecord: 30,
      tableHeaders: HakuenTableHeaders,
      editRecord: new Hakuen(new DailyWorkReportKey({ workDate: DATE })),
      reportData: [] as Hakuen[], //テーブルデータ
      title: "剥鉛作業記録",
      tableName: "Hakuen",
      isLoading: false,
      codeGroup: [] as PulldownResponse[],
      userItems: [] as CheckboxGroupItems[], //作業者
      machineCodeItems: [] as CheckboxGroupItems[], //撚線機械名
      isView: false,
      fileUrl: ""
    };
  },
  computed: {
    headers() {
      return [
        {
          ...HakuenText.workDate,
          type: "date-picker",
          disabled: true
        },
        {
          ...HakuenText.machineCode,
          type: "select",
          items: this.machineCodeItems
        },
        {
          ...HakuenText.worker,
          type: "select",
          items: this.userItems,
          required: true
        },
        { ...HakuenText.inspectionFlag, type: "checkbox" },
        { ...HakuenText.acceptNo, type: "text", maxLength: 40, search: true },
        { ...HakuenText.successFlag, type: "checkbox" },
        { ...HakuenText.preProcessDrum, type: "text", maxLength: 40 },
        { ...HakuenText.specificationNo, type: "text", maxLength: 40 },
        { ...HakuenText.itemName, type: "text", maxLength: 80 },
        { ...HakuenText.size, type: "text", maxLength: 80 },
        { ...HakuenText.length, type: "number", digit: 10, scale: 2 },
        { ...HakuenText.suryoMeisai, type: "text", maxLength: 80 },
        { ...HakuenText.windingDrum, type: "text", maxLength: 40 },
        { ...HakuenText.abnormality, type: "text", maxLength: 255 },
        { ...HakuenText.biko, type: "text", maxLength: 255 }
      ] as Headers[];
    },
    tableRecord() {
      const records: Hakuen[] = this.reportData;
      const result = records.map((h: Hakuen) => {
        const record = new HakuenTable(h);
        const date = moment(h.updateTs).format(DATE_FORMAT.DATE_MINUTE);
        record.updateTs = date;
        return record;
      });
      return result;
    }
  },
  methods: {
    /**
     * 追加
     */
    onAdd() {
      console.log("add");
      this.isAdd = true;
      if (this.reportData.length >= this.maxRecord) {
        this.snackbarText = `${this.maxRecord}列以上は登録できません。`;
        this.snackbar = true;
        return;
      }
      const reocrdKey = new DailyWorkReportKey({ workDate: this.selectDate });
      this.editRecord = new Hakuen(reocrdKey);
      this.isOpen = true;
    },
    /**
     * 編集
     * @param value index
     */
    onEdit(value: number) {
      console.log("edit");
      this.isAdd = false;
      this.editRecord = Object.assign({}, this.reportData[value]);
      this.isOpen = true;
    },
    /**
     * コピー
     * @param value DailyWorkReportSearchResponse
     */
    async onCopy(value: DailyWorkReportSearchResponse, close: () => void) {
      console.log("copy", value);
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = new DailyWorkReportKey(value);
        const result = await api.$post<Hakuen>(
          api.Paths.dailyWorkReport.hakuen.searchKey,
          param
        );
        result.workDate = this.selectDate;
        this.editRecord = result;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("copy end");
        this.isLoading = false;
      }
    },
    async onFind(value: OrderDetailsSearchResponse, close: () => void) {
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        if (value.alreadyAssigned === null) {
          showDialogs.$error("受注番号の登録がありません。");
        } else {
          if (value.alreadyAssigned) {
            showDialogs.$error("既に他の作業日報データで引用されています。");
            value.juhno = null;
            value.syno = null;
            value.shonm = null;
            value.kikaku = null;
            value.denno = null;
          }
        }
        this.editRecord.acceptNo = value.juhno;
        this.editRecord.specificationNo = value.syno;
        this.editRecord.itemName = value.shonm;
        this.editRecord.size = value.kikaku;
        this.editRecord.denno = value.denno;
        this.editRecord.gyono = value.gyono;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onView(index: number) {
      const api = new Api();
      this.fileUrl = `${process.env.VUE_APP_API_BASE_URL}${api.Paths.specDocs.searchkey}?token=${Store.state.mtoken}&denno=${this.reportData[index].denno}&gyono=${this.reportData[index].gyono}`;
      this.isView = true;
    },
    // ==============================
    // 検索
    // ==============================
    async onSearch() {
      console.log("onSearch start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        this.reportData = await this.getHakuenRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSearch end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 保存
    // ==============================
    async onSave(saveValue: Hakuen) {
      console.log("onSave start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      this.isLoading = true;
      try {
        if (this.isAdd) {
          await api.$post(api.Paths.dailyWorkReport.hakuen.insert, saveValue);
        } else {
          await api.$post(api.Paths.dailyWorkReport.hakuen.update, saveValue);
        }
        showDialogs.$info("更新しました。", "更新");
        this.reportData = await this.getHakuenRecord();
        this.isOpen = false;
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSave end");
        this.isLoading = false;
      }
    },
    /**
     * 削除
     * @param index
     */
    async onDelete(index: number) {
      console.log("onDelete start", index);
      const api = new Api();
      const showDialogs = new ShowDialogs();
      const title = "確認";
      const message = `${index +
        1}列目の作業記録を削除します。\nよろしいですか？`;
      const confirm = await showDialogs.$deleteConfirm(message, title);
      if (!confirm) return;

      this.isLoading = true;
      try {
        const target = this.reportData[index];
        await api.$post(api.Paths.dailyWorkReport.hakuen.delete, target);
        showDialogs.$info("削除しました。", "情報");
        this.reportData = await this.getHakuenRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onDelete end");
        this.isLoading = false;
      }
    },
    /**
     * 剥鉛作業記録データ取得
     */
    async getHakuenRecord() {
      const api = new Api();
      const param = { workDate: new Date(this.selectDate) };
      const result = await api.$post<Hakuen[]>(
        api.Paths.dailyWorkReport.hakuen.search,
        param
      );
      return result;
    },
    /**
     * データベースから選択項目データ取得
     */
    async getItems() {
      console.log("getItems start");
      const showDialogs = new ShowDialogs();
      const api = new Api();
      this.isLoading = true;
      try {
        //apiパス
        const userPath = api.Paths.shainSearch;
        const codeMasterPath = api.Paths.codeMasterPulldown;
        const codeGroupPath = api.Paths.codeGroupPulldown;

        //リクエスト
        const shainRequest = new ShainSearchRequest({
          department: [this.$store.state.dailyWorkReportStore.nippoType],
          isValid: "1"
        });
        const userPromise = api.$post<Shain[]>(userPath, shainRequest);
        const codeMasterPromise = api.$get<PulldownResponse[]>(codeMasterPath);
        const codeGroupPromise = api.$get<PulldownResponse[]>(codeGroupPath);
        const [user, codeMaster, codeGroup] = await Promise.all([
          userPromise,
          codeMasterPromise,
          codeGroupPromise
        ]);

        this.codeGroup = codeGroup;
        //ユーザデータ取得
        this.userItems = user.map(e => ({ value: e.userId, text: e.userName }));
        //コードマスタのデータをすべて取得
        //剥鉛機械名
        const machine = codeKbnFilter(codeMaster, CodeKbnList.HAKUEN);
        this.machineCodeItems = pulldownToCheckItems(machine);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
        this.isLoading = false;
      }
    },
    /**
     * テーブルコード取得
     */
    async getCode() {
      console.log("getCode start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const path = api.Paths.dailyWorkReport.hakuen.code;

        //リクエスト
        const code = await api.$get<string>(path);

        this.$store.commit("dailyWorkReportStore/nippoTypeSet", code);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    }
  },
  async created() {
    await this.getCode();
    await this.getItems();
    await this.onSearch();
  }
});
